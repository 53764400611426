import React, { useState, useRef } from "react"
import { database, storage, functions } from "../../firebase"
// Third party
import { v4 as uuidv4 } from "uuid"
import { IoClose } from "react-icons/io5"

// styles
import * as S from "./FormStyles"
// Reeler components
import PublicNotFound from "../commons/NotFound/PublicNotFound"
import InputWidget from "./components/InputWidget"
import TextWidget from "./components/TextWidget"
import MultiplechoiceWidget from "./components/MultipleChoiceWidget"
import ThirdPartyApprovalWidget from "./components/ThirdPartyApprovalWidget"
import DividerWidget from "./components/DividerWidget"
import FileWidget from "./components/FileWidget"
import ImageWidget from "./components/ImageWidget"
import TermsWidget from "./components/TermsWidget"
import SubmitButtonWidget from "./components/SubmitButtonWidget"

// Reeler commons
import { humanFileSize } from "../../utils/HumanFileSize"
import { getAssetMediaType } from "../../utils/GetAssetMediaType"
import {
  ASSET_STATE,
  RIGHTS_REQUEST_TYPE,
  RIGHT_REQUEST_STATUS,
  ASSET_RIGHT_STATUS,
  RIGHTS_REQUEST_APPROVAL_STATUS,
  MEDIA_TYPE,
} from "../../constants"
import ShortUniqueId from "short-unique-id"
import FormFooter from "./components/FormFooter"
const { randomUUID } = new ShortUniqueId({ length: 6 })

export default function FormWidget({ campaignId, campaign, isActive = true }) {
  // States
  const [saving, setSaving] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [uploadingFiles, setUploadingFiles] = useState([])
  const [fileMessage, setFileMessage] = useState()
  const [uploadError, setUploadError] = useState(false)
  const [bytesTransferred, setBytesTransferred] = useState([])
  const [uploadSuccess, setUploadSuccess] = useState(false)
  // Refs
  const refs = useRef({})

  const handleUpload = () => {
    const files = refs.current["file"].files

    setFileMessage(null)

    if (files === null) return

    let maxNoFilesPerSubmission =
      campaign.formSchema.properties.file?.maxNoFilesPerSubmission

    // check if files.length + uploadingFiles > 0
    // check if campaign has any limits
    let totalNumberOfFiles = files.length + uploadingFiles.length

    if (totalNumberOfFiles > parseInt(maxNoFilesPerSubmission)) {
      setFileMessage(
        `You can't upload more than ${maxNoFilesPerSubmission} file(s).`
      )
      return
    }

    let fileSizeLimit = campaign.formSchema.properties.file?.fileSizeLimit

    Array.from(files).forEach(file => {
      let format = file.name.split(".").pop().toLowerCase()
      let allowedFormats = [
        "mp4",
        "mov",
        "png",
        "jpg",
        "jpeg",
        "tiff",
        "tif",
        "mp3",
        "wav",
        "ogg",
        "m4a",
        "aif",
        "aiff",
      ]
      if (!allowedFormats.includes(format)) {
        setFileMessage(
          "File format can only be mp4, mov, png, jpg, jpeg, mp3, wav or ogg."
        )
        return
      }

      // Check if account has set any uploading file size restrictions in bytes
      if (fileSizeLimit) {
        if (file.size > fileSizeLimit) {
          setFileMessage(
            "File size is too big. File size limit is " +
              humanFileSize(fileSizeLimit, true, 0)
          )
          return
        }
      } else {
        if (file.size > 1000000000) {
          setFileMessage("File size is too big. File size limit is 1GB")
          return
        }
      }

      // Can not upload duplicates
      if (uploadingFiles.some(f => f.name === file.name)) {
        setFileMessage("File already exists in the list.")
        return
      }

      setUploadingFiles(prevUploadingFiles => [...prevUploadingFiles, file])
    })
  }

  const handleRemoveFile = id => {
    setFileMessage(null)

    // Remove file from uploadingFiles
    const filteredUploadingFiles = uploadingFiles.filter(
      (uploadingFile, index) => index !== id
    )
    setUploadingFiles(filteredUploadingFiles)
    refs.current["file"].value = null
  }

  const getCreatorId = async (creatorEmail, campaignId) => {
    var getCreatorIdForEmail = functions.httpsCallable("getcreatoridforemail")

    try {
      const { data } = await getCreatorIdForEmail({
        creatorEmail,
        campaignId,
      })
      return data
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setDisabled(true)
    setSaving(true)
    setFileMessage(null)
    let thirdPartyApprovalParentDoc = undefined
    let confirmationDoc = undefined
    // Check if we have a file

    if (campaign?.formSchema?.properties["file"]?.required && !uploadingFiles) {
      setFileMessage("You need to upload at least one media file.")
      setDisabled(false)
      setSaving(false)
      return
    }

    // Get campaign tags
    let tags = campaign.tags ? campaign.tags : {}

    // Create new doc
    let doc = {
      values: {},
      order: campaign.formSchema.order,
      tags,
      campaign_type: campaign.campaignType,
      library: false,
      state: ASSET_STATE.unreviewed,
      createdAt: database.getCurrentTimestamp(),
      campaignId: campaignId,
      accountId: campaign.accountId ? campaign.accountId : "",
    }

    // Loop through all fields and collect their values and add to doc
    Object.keys(refs.current).forEach(fieldId => {
      let field = campaign.formSchema.properties[fieldId]
      // Check if field is Multichoice and check what format it has
      if (field.widget === "multiple_choice") {
        if (field.format === "select") {
          doc.values[fieldId] = {
            label: field.title ? field.title : fieldId,
            value: refs.current[fieldId].value,
          }

          // Retriev correct option, should only be one, from field to check for tags and add to asset.
          let option = field.options.filter(
            option => option.label === refs.current[fieldId].value
          )[0]

          if (option?.tags) {
            doc.tags = { ...doc.tags, ...option.tags }
          }
        } else if (["checkbox", "radio"].includes(field.format)) {
          let values = []
          Object.keys(refs.current[fieldId]).forEach(i => {
            if (refs.current[fieldId][i].checked) {
              values.push(field.options[i].label)

              doc.tags = { ...doc.tags, ...field.options[i].tags }
            }
          })

          doc.values[fieldId] = {
            label: field.title ? field.title : fieldId,
            values: values,
          }
        }
      }

      if (field.widget === "input") {
        let inputValues = {
          label: field.title ? field.title : "",
          value: refs.current[fieldId].value,
        }

        if (field?.dataFieldName) {
          inputValues["database_name"] = field.dataFieldName
        }

        doc.values[fieldId] = inputValues

        // Check if we should add a tag
        if (field.tag && refs.current[fieldId].value) {
          refs.current[fieldId].value.split(" ").forEach(tag => {
            doc.tags[tag.toLowerCase()] = true
          })
        }
      }

      if (field.widget === "terms") {
        let termsValues = {
          label: "Terms",
          value: field.terms,
        }

        if (field?.dataFieldName) {
          termsValues["database_name"] = field.dataFieldName
        }

        doc.values[fieldId] = termsValues
      }

      // Check if there is a third party approval field and email, if so create a new third party approval doc
      if (field.widget === "third_party_approval") {
        const thirdPartEmail = refs?.current[fieldId]?.value

        if (!thirdPartyApprovalParentDoc && thirdPartEmail) {
          const shortId = randomUUID()

          thirdPartyApprovalParentDoc = {
            ...field,
            requestType: RIGHTS_REQUEST_TYPE.thirdParty,
            third_party_email: thirdPartEmail,
            contactDetails: {
              email: thirdPartEmail,
            },
            children: [],
            accountId: campaign.accountId,
            shortId,
            linkStatus: RIGHT_REQUEST_STATUS.created,

            link:
              process.env.REACT_APP_FIREBASE_CREATOR_APP_URL + "/" + shortId,
            createdAt: database.getCurrentTimestamp(),
          }

          //If there is a third approval doc, set status to pending approval

          // what status should we have if there is a third party approval field but no email?
          doc["thirdPartyApprovalStatus"] =
            RIGHTS_REQUEST_APPROVAL_STATUS.pending
        }
      }

      // field.filename is a boolean to indicate that the answer/value should be added when generating a filename
      if (field.filename) {
        doc.values[fieldId] = {
          ...doc.values[fieldId],
          filename: field.filename,
        }
      }
    })

    // Get exising creator id, otherwise create a new creator

    const creatorEmail = doc.values["email"]?.value
    if (creatorEmail) {
      try {
        const creatorId = await getCreatorId(creatorEmail, campaignId)

        doc["creatorId"] = creatorId
      } catch (err) {
        console.log(err)
      }
    }

    // Add values that should be shown on third party approval page
    if (
      thirdPartyApprovalParentDoc &&
      thirdPartyApprovalParentDoc?.form_fields_on_approval_page
    ) {
      thirdPartyApprovalParentDoc["form_fields_values"] = []
      Array.from(
        thirdPartyApprovalParentDoc?.form_fields_on_approval_page
      ).forEach(id => {
        let value = doc.values[id]
        if (value) {
          if (
            !thirdPartyApprovalParentDoc?.form_fields_on_approval_page?.includes(
              value
            )
          ) {
            thirdPartyApprovalParentDoc["form_fields_values"] = [
              ...thirdPartyApprovalParentDoc["form_fields_values"],
              value,
            ]
          }
        }
      })
    }

    // Create confirmation email doc
    if (campaign?.confirmationEmail) {
      confirmationDoc = {
        ...campaign?.confirmationEmail,
        accountId: campaign.accountId,
        values: doc?.values,
        order: campaign?.formSchema?.order,
        assets: [],
        createdAt: database.getCurrentTimestamp(),
      }
    }

    if (uploadingFiles.length > 0) {
      const promises = uploadingFiles.map(async (uploadingFile, index) => {
        return new Promise(function (resolve, reject) {
          // Create an upload task. Store UGC file under campaignID

          // Generate an id for filename
          let accessToken = uuidv4()
          let format = uploadingFile.name.split(".").pop()
          let newFileName = accessToken + "." + format
          let filePath = campaign.accountId + "/" + campaignId + "/"

          doc["status"] = ASSET_RIGHT_STATUS.approved

          const uploadTask = storage
            .ref(filePath + newFileName)
            .put(uploadingFile, {
              metadata: {
                metadata: {
                  firebaseStorageDownloadTokens: accessToken,
                },
              },
            })

          // Add object with asset id and url to the array

          uploadTask.on(
            "state_changed",
            snapshot => {
              setBytesTransferred(prevBytesTransferred => {
                const newBytesTransferred = [...prevBytesTransferred]
                newBytesTransferred[index] = snapshot.bytesTransferred
                return newBytesTransferred
              })
            },
            err => {
              // Manage errors better, what happens when a file upload error occurs??
              resolve()
              console.log(err)
              setUploadError(true)
              setDisabled(false)
              setSaving(false)
            },
            async () => {
              const url = await uploadTask.snapshot.ref.getDownloadURL()

              // Retrieve media type
              let mediaType = getAssetMediaType(uploadingFile.name)

              // add file_name, size and type to the doc
              doc["file_name"] = newFileName
              doc["file_path"] = filePath
              doc["access_token"] = accessToken
              doc["size"] = uploadingFile.size
              doc["type"] = uploadingFile.type
              doc["extension"] = uploadingFile.name.split(".").pop()
              doc["media_type"] = mediaType
              // add the url to the asset doc
              doc["url"] = url
              doc["assetVersions"] = [
                {
                  id: uuidv4(),
                  original: true,
                  main: true,
                  title: "original",
                  file_name: newFileName,
                  file_path: filePath,
                  access_token: accessToken,
                  original_file_name: uploadingFile.name,
                  size: uploadingFile.size,
                  type: uploadingFile.type,
                  url: url,
                  modifiedAt: Date.now(),
                  createdAt: Date.now(),
                },
              ]

              database.assets
                .add(doc)
                .then(async docRef => {
                  // If Third Party Approval doc exists, then add asset
                  if (thirdPartyApprovalParentDoc) {
                    // Create new child rights request to the parent

                    let childRightsRequestDoc = {
                      requestType: RIGHTS_REQUEST_TYPE.thirdParty,
                      contactDetails:
                        thirdPartyApprovalParentDoc.contactDetails,
                      assetId: docRef.id,

                      accountId: campaign.accountId,
                      linkStatus: RIGHT_REQUEST_STATUS.created,
                      approvalStatus: ASSET_RIGHT_STATUS.pending,
                      approvalStatusLog: [
                        {
                          status: ASSET_RIGHT_STATUS.pending,
                          note: "Rights request created from form",
                          createdAt: Date.now(),
                        },
                      ],
                      link:
                        process.env.REACT_APP_FIREBASE_CREATOR_APP_URL +
                        "/" +
                        thirdPartyApprovalParentDoc.shortId,
                      createdAt: database.getCurrentTimestamp(),
                    }

                    const childRef = await database.rightRequests.add(
                      childRightsRequestDoc
                    )

                    thirdPartyApprovalParentDoc.children.push(childRef.id)
                  }

                  if (confirmationDoc) {
                    confirmationDoc.assets.push({
                      id: docRef.id,
                      url: url,
                      type: uploadingFile.type,
                      media_type: mediaType,
                    })
                  }
                })
                .then(() => {
                  resolve()
                })

                .catch(error => {
                  resolve()
                  console.error("Error adding document: ", error)
                })
            }
          )
        })
      })

      Promise.all(promises).then(async () => {
        if (thirdPartyApprovalParentDoc) {
          // add to firebase
          await database.rightRequests
            .add(thirdPartyApprovalParentDoc)
            .catch(error => {
              console.error(
                "Error adding third pary approval document: ",
                error
              )
            })
        }
        if (confirmationDoc) {
          // add to firebase
          await database.confirmations.add(confirmationDoc).catch(error => {
            console.error("Error adding confirmation document: ", error)
          })
        }

        // IF there are any redirect url
        if (campaign?.formSchema?.redirectUrl) {
          window.location.replace(campaign?.formSchema?.properties?.redirectUrl)
        } else if (
          campaign?.formSchema?.properties?.submitButton?.redirectUrl
        ) {
          window.location.replace(
            campaign?.formSchema?.properties?.submitButton?.redirectUrl
          )
        }
        setUploadSuccess(true)
        setSaving(false)
      })
    } else {
      // Without any uploading files
      doc["media_type"] = MEDIA_TYPE.TEXT
      database.assets.add(doc).then(async docRef => {
        if (thirdPartyApprovalParentDoc) {
          let childRightsRequestDoc = {
            requestType: RIGHTS_REQUEST_TYPE.thirdParty,
            contactDetails: thirdPartyApprovalParentDoc.contactDetails,
            assetId: docRef.id,

            accountId: campaign.accountId,
            linkStatus: RIGHT_REQUEST_STATUS.created,
            approvalStatus: ASSET_RIGHT_STATUS.pending,
            approvalStatusLog: [
              {
                status: ASSET_RIGHT_STATUS.pending,
                note: "Rights request created from form",
                createdAt: Date.now(),
              },
            ],
            link:
              process.env.REACT_APP_FIREBASE_CREATOR_APP_URL +
              "/" +
              thirdPartyApprovalParentDoc.shortId,
            createdAt: database.getCurrentTimestamp(),
          }

          const childRef = await database.rightRequests.add(
            childRightsRequestDoc
          )

          thirdPartyApprovalParentDoc.children.push(childRef.id)

          // Add parameter to tell that this is rights request is missing content
          thirdPartyApprovalParentDoc["media_type"] = MEDIA_TYPE.TEXT

          // add to firebase
          await database.rightRequests
            .add(thirdPartyApprovalParentDoc)
            .catch(error => {
              console.error(
                "Error adding third pary approval document: ",
                error
              )
            })
        }

        if (confirmationDoc) {
          confirmationDoc.assets.push({
            id: docRef.id,

            type: MEDIA_TYPE.TEXT,
          })
        }

        if (confirmationDoc) {
          // add to firebase
          await database.confirmations.add(confirmationDoc).catch(error => {
            console.error("Error adding confirmation document: ", error)
          })
        }
        // IF there are any redirect url
        if (campaign?.formSchema?.redirectUrl) {
          window.location.replace(campaign?.formSchema?.properties?.redirectUrl)
        } else if (
          campaign?.formSchema?.properties?.submitButton?.redirectUrl
        ) {
          window.location.replace(
            campaign?.formSchema?.properties?.submitButton?.redirectUrl
          )
        }
        setUploadSuccess(true)
        setSaving(false)
      })
    }
  }

  return campaign ? (
    <S.Styles design={campaign.formSchema?.design}>
      <S.Container
        formBackground={campaign.formSchema?.design?.formBackground}
        fontColor={campaign.formSchema?.design?.fontColor}
      >
        <form onSubmit={handleSubmit}>
          {campaign.formSchema.order.map((fieldId, index) => {
            let field = campaign.formSchema.properties[fieldId]
            switch (field?.widget) {
              case "input":
                return (
                  <InputWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    index={index}
                    refArray={refs}
                    disabled={disabled || !isActive}
                    design={campaign.formSchema?.design}
                  />
                )
              case "text":
                return <TextWidget key={fieldId} field={field} />
              case "multiple_choice":
                return (
                  <MultiplechoiceWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    refArray={refs}
                    disabled={disabled || !isActive}
                    design={campaign.formSchema?.design}
                  />
                )
              case "terms":
                return (
                  <TermsWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    refArray={refs}
                    disabled={disabled || !isActive}
                    design={campaign.formSchema?.design}
                  />
                )
              case "third_party_approval":
                return (
                  <ThirdPartyApprovalWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    index={index}
                    refArray={refs}
                    disabled={disabled || !isActive}
                    design={campaign.formSchema?.design}
                  />
                )
              case "divider":
                return (
                  <DividerWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    index={index}
                    refArray={refs}
                    disabled={disabled || !isActive}
                  />
                )

              case "file":
                return (
                  <>
                    <FileWidget
                      key={fieldId}
                      fieldId={fieldId}
                      field={field}
                      index={index}
                      refArray={refs}
                      disabled={disabled || !isActive}
                      handleUpload={handleUpload}
                      design={campaign.formSchema?.design}
                    />
                    {fileMessage ? (
                      <S.FileMessageContainer>
                        {fileMessage}
                      </S.FileMessageContainer>
                    ) : null}
                    {uploadingFiles ? (
                      <S.FilesContainer>
                        {uploadingFiles.map((uploadingFile, index) => (
                          <S.File key={index}>
                            <S.FileName>{uploadingFile.name}</S.FileName>
                            {!disabled ? (
                              <S.RemoveFile
                                onClick={() => handleRemoveFile(index)}
                              >
                                <IoClose size="14px" />
                              </S.RemoveFile>
                            ) : null}
                          </S.File>
                        ))}
                      </S.FilesContainer>
                    ) : null}
                  </>
                )

              case "image":
                return (
                  <ImageWidget
                    key={fieldId}
                    fieldId={fieldId}
                    field={field}
                    index={index}
                    refArray={refs}
                  />
                )
              case "submitButton":
                return (
                  <S.WidgetContainer key={fieldId}>
                    <SubmitButtonWidget
                      design={campaign.formSchema?.design}
                      field={campaign.formSchema.properties.submitButton}
                      successMessage={
                        campaign.formSchema?.submitMessage
                          ? campaign.formSchema?.submitMessage
                          : campaign.formSchema.properties?.submitButton
                              ?.submitMessage
                          ? campaign.formSchema.properties.submitButton
                              .submitMessage
                          : "Thank you for participating"
                      }
                      uploadSuccess={uploadSuccess}
                      bytesTransferred={bytesTransferred}
                      totalBytes={uploadingFiles.reduce(
                        (accumulator, file) => accumulator + file?.size,
                        0
                      )}
                      uploadError={uploadError}
                      saving={saving}
                      disabled={disabled || !isActive}
                    />
                  </S.WidgetContainer>
                )
              default:
                return null
            }
          })}

          <FormFooter campaignId={campaignId} />
        </form>
      </S.Container>
    </S.Styles>
  ) : (
    <PublicNotFound />
  )
}
